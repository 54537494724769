'use client';
import { Container } from '@/components/pageComponents/container/container';
import { sanitizeHtml } from 'helpers/sanitizeHtml';
import { marked } from 'marked';
import { useMemo } from 'react';

import styles from './paragraph.module.scss';

export interface ParagraphProps {
  className?: string;
  style: 'regular' | 'large';
  children: string;
  contained?: boolean;
}

export const Paragraph = ({
  className,
  style,
  children,
  contained = true,
}: ParagraphProps) => {
  const paragraph = useMemo(() => {
    const copy = marked(children, { gfm: true, breaks: true }).replace(
      /\[code\]capi/gi,
      '<strong class="codecapi">code</strong>capi'
    );

    return (
      <div
        className={`${styles.paragraph} ${styles[style]} ${className ?? ''}`}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(copy),
        }}
      />
    );
  }, [children, className, style]);

  return contained ? (
    paragraph
  ) : (
    <Container size="narrow">{paragraph}</Container>
  );
};
