import styles from './tileCarousel.module.scss';
import useEmblaCarousel from 'embla-carousel-react';
import { ReactNode } from 'react';

type TileCarouselProps = {
  children: ReactNode[];
};

const TileCarousel = ({ children }: TileCarouselProps) => {
  const [emblaRef] = useEmblaCarousel({ dragFree: true });

  return (
    <div ref={emblaRef}>
      <div className={styles.itemsWrapper}>{children}</div>
    </div>
  );
};

export { TileCarousel };
