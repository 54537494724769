import sanitize from 'sanitize-html';

export const sanitizeHtml = (copy: string) => {
  return sanitize(copy, {
    allowedTags: ['em', 'a', 'u', 'p', 'strong', 'ul', 'li', 'br', 'span'],
    allowedAttributes: {
      a: ['href', 'rel', 'target'],
      strong: ['class'],
      span: ['class'],
    },
  });
};
