import IntlMessageFormat from 'intl-messageformat';
import { ReactNode } from 'react';

import styles from './heading.module.scss';

export interface HeadingProps {
  className?: string;
  style: 'regular' | 'regularAlt' | 'compact' | 'sub';
  level: 'h2' | 'h3';
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  children: ReactNode;
}

export const Heading = ({
  className,
  style,
  level,
  tag,
  children,
}: HeadingProps) => {
  const Component = tag;

  const copy: ReactNode =
    typeof children === 'string'
      ? new IntlMessageFormat(children).format({
          em: (chunks) => <em key={chunks.toString()}>{chunks}</em>,
          span: (chunks) => <span key={chunks.toString()}>{chunks}</span>,
        })
      : children;

  return (
    <Component
      className={`${styles.heading} ${styles[style]} ${styles[level]} ${
        className ?? ''
      }`}
    >
      {copy}
    </Component>
  );
};
