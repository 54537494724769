import Image from 'next/image';
import styles from '../tile.module.scss';
import { BaseTileProps } from '../types';
import classNames from 'classnames';
import { Typography } from '../../typography';

export interface NewsTileProps extends BaseTileProps {
  date: string;
  type: string;
}

export const NewsTile = ({ title, image, alt, date, type }: NewsTileProps) => {
  return (
    <div className={styles.tileContainer}>
      <div className={styles.imageContainer}>
        <Image
          alt={alt}
          className={styles.image}
          src={image}
          fill
          title={alt}
        />
      </div>
      <div
        className={classNames([styles.textContainer, styles.newsTextContainer])}
      >
        <div className={styles.newsTitleContainer}>
          <Typography tag="span" variant="label-reg">
            {type}
          </Typography>
          <Typography tag="span" variant="label-reg" color="muted">
            {date}
          </Typography>
        </div>
        <Typography tag="h3" variant="heading3-regular">
          {title}
        </Typography>
      </div>
    </div>
  );
};
