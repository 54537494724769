'use client';
import { ButtonProps } from '@/components/commonComponents/button/button';
import { Heading } from '@/components/commonComponents/heading/heading';
import { Paragraph } from '@/components/commonComponents/paragraph/paragraph';
import { Container } from '@/components/pageComponents/container/container';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { Button } from 'components/buttons';
import { ButtonStyleTypes } from 'components/buttons/types';

import styles from './textBlock.module.scss';

export interface TextBlockProps {
  className?: string;
  title?: string | ReactNode;
  headingStyle?: 'regular' | 'compact';
  headingLevel?: 'h2' | 'h3';
  paragraphStyle?: 'regular' | 'large';
  copy: string;
  buttonLabel?: string;
  buttonLink?: ButtonProps['href'];
  buttonStyle?: ButtonStyleTypes;
  style?: 'row' | 'column';
  textAlign?: 'left' | 'center' | 'right';
  contained?: boolean;
}

export const TextBlock = ({
  className,
  style = 'row',
  title,
  headingStyle = 'regular',
  headingLevel = 'h2',
  paragraphStyle = 'regular',
  copy,
  buttonLabel,
  buttonLink,
  buttonStyle = 'primary',
  textAlign = 'left',
  contained = true,
}: TextBlockProps) => {
  const html = (
    <div
      className={classNames(
        styles.textBlock,
        styles[style],
        styles[textAlign],
        className
      )}
    >
      <div className={styles.copy}>
        {title && (
          <Heading style={headingStyle} level={headingLevel} tag="h2">
            {title}
          </Heading>
        )}
        <Paragraph style={paragraphStyle}>{copy}</Paragraph>
      </div>
      {buttonLabel && buttonLink && (
        <div className={buttonStyle}>
          <Button style={{ type: buttonStyle }} href={buttonLink}>
            {buttonLabel}
          </Button>
        </div>
      )}
    </div>
  );

  return contained ? <Container size="narrow">{html}</Container> : html;
};
