'use client';
import { PrimaryButton } from 'components/buttons';

import { Heading } from '../../commonComponents/heading/heading';
import { Paragraph } from '../../commonComponents/paragraph/paragraph';
import {
  Illustration,
  Illustrations,
} from '../../internalComponents/illustration/illustration';
import { Container } from '../../pageComponents/container/container';
import { TextBlock } from '../textBlock/textBlock';
import styles from './illustrationRow.module.scss';
import { ButtonType } from '@/types/misc';

export interface IllustrationRowProps extends ButtonType {
  title?: string;
  copy?: string;
  items: {
    illustration: keyof typeof Illustrations;
    title: string;
    copy: string;
  }[];
}

export const IllustrationRow = ({
  title,
  copy,
  items,
  buttonLabel,
  buttonLink,
}: IllustrationRowProps) => {
  return (
    <>
      {(title || copy) &&
        (copy ? (
          <TextBlock title={title} copy={copy} />
        ) : (
          <Container size="narrow">
            <Heading level="h2" style="regular" tag="h2">
              {title}
            </Heading>
          </Container>
        ))}
      <Container size="wide" layoutBlock="illustration">
        {items.map((item) => (
          <div key={item.title} className={styles.illustrationRowItem}>
            <Illustration
              name={item.illustration}
              className={styles.illustration}
            />
            <div className={styles.content}>
              <Heading level="h3" style="regular" tag="h3">
                {item.title}
              </Heading>
              <Paragraph style="regular">{item.copy}</Paragraph>
            </div>
          </div>
        ))}
      </Container>
      {buttonLabel && buttonLink && (
        <Container size="narrow" layoutBlock="centered">
          <PrimaryButton href={buttonLink}>{buttonLabel}</PrimaryButton>
        </Container>
      )}
    </>
  );
};
