import Image from 'next/image';
import classNames from 'classnames';

import { BaseTileProps } from '../types';
import { Typography } from '../../typography';
import styles from '../tile.module.scss';

export interface TeamTileProps extends BaseTileProps {
  position: string;
}

export const TeamTile = ({ title, image, alt, position }: TeamTileProps) => {
  return (
    <div className={styles.tileContainer}>
      <div className={classNames([styles.imageContainer, styles.teamImage])}>
        <Image
          alt={alt}
          src={image}
          fill
          className={styles.image}
          title={alt}
        />
      </div>
      <div
        className={classNames([styles.textContainer, styles.teamTextContainer])}
      >
        <Typography tag="h3" variant="heading3-regular">
          {title}
        </Typography>
        <Typography tag="span" variant="heading3-sub">
          {position}
        </Typography>
      </div>
    </div>
  );
};
