'use client';
import { Heading } from '@/components/commonComponents/heading/heading';
import { Typography } from '../../typography';
import { Button } from '@/components/commonComponents/button/button';

import { TeamTile, type TeamTileProps } from './teamTile';

import styles from '../tileCarousel.module.scss';

import type { BaseTileCarouselProps } from '../types';
import { TileCarousel } from '../tileCarousel';

export interface TeamCarouselProps extends BaseTileCarouselProps {
  items: TeamTileProps[];
}

const headerButtonMap = {
  label: 'Ontmoet het team',
  link: '/team',
};

const TeamCarousel = ({
  heading,
  items,
  introParagraph,
}: TeamCarouselProps) => {
  return (
    <div className={styles.carouselWrapper}>
      <div className={styles.carouselHeaderWrapper}>
        <div className={styles.carouselHeader}>
          {heading && (
            <Heading level="h2" style="regular" tag="h2">
              {heading}
            </Heading>
          )}
          {introParagraph && (
            <Typography tag="p" variant="paragraph-reg">
              {introParagraph}
            </Typography>
          )}
        </div>
        <Button
          type="button"
          style="primary"
          icon="ArrowRight"
          href={headerButtonMap.link}
          className={styles.headerButton}
        >
          {headerButtonMap.label}
        </Button>
      </div>
      <TileCarousel>
        {items?.map((item) => (
          <TeamTile key={item?.title} {...item} />
        ))}
      </TileCarousel>
      <Button
        type="button"
        style="primary"
        icon="ArrowRight"
        href={headerButtonMap.link}
        className={styles.mobileButton}
      >
        {headerButtonMap.label}
      </Button>
    </div>
  );
};

export { TeamCarousel };
