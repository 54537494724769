import Image from 'next/image';

import { Typography } from '../../typography';
import { Pill } from '../../pill';

import styles from '../tile.module.scss';
import { BaseTileProps } from '../types';

export interface CaseTileProps extends BaseTileProps {
  company: string;
  tags: string[];
}

export const CaseTile = ({
  title,
  image,
  alt,
  company,
  tags,
}: CaseTileProps) => {
  return (
    <div className={styles.tileContainer}>
      <div className={styles.imageContainer}>
        <Image
          alt={alt}
          src={image}
          fill
          className={styles.image}
          title={alt}
        />
      </div>
      <div className={styles.textContainer}>
        <Typography tag="span" variant="label-reg" color="muted">
          {company}
        </Typography>
        <Typography tag="h3" variant="heading3-regular">
          {title}
        </Typography>
        <div className={styles.tagsContainer}>
          {tags.map((label) => (
            <Pill key={label}>{label}</Pill>
          ))}
        </div>
      </div>
    </div>
  );
};
